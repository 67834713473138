

import { CreateTenantErrorTypes } from "@/types/enums";
import { CreateTenantPayload } from "@/types/requests";
import { CreateTenantErrorResponse } from "@/types/responses"  

import Vue, { nextTick } from "vue";
export default Vue.extend({
    name: "PrimaryContactInput",
    props: {
        errorResponse: {
            type:  Object as () => CreateTenantErrorResponse || null,
			required: false
        }
    },
    data(): {
            loading: boolean
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            phoneRules: any
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            emailRules: any
            emailInput: string
            phoneInput: string
            isPrimaryContact: boolean
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            bindProps: any
            //error-overrides
            emailError: boolean
            emailErrorMsg: string[]
            phoneError: boolean
            phoneErrorMsg: string[]
    } {
        return {
            loading: false,
            phoneRules: [
            (v: string) => /^(?:.*\d){8,}$/.test(v) || 'Phone number must be atleast 8 digits.', // [a-zæøåA-ZÆØÅäöÄÖ\- ]
            ],
            emailRules: [
                (v: string) => /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(v) || 'Please use a valid e-mail format',
            ],
            emailInput: "",
            phoneInput: "",
            isPrimaryContact: false,
            bindProps: {
                mode: "international",
                defaultCountry: "NO",
                disabledFetchingCountry: false,
                disabled: false,
                disabledFormatting: false,
                placeholder: "Enter a phone number",
                required: false,
                enabledCountryCode: false,
                enabledFlags: true,
                preferredCountries: ["NO", "SE", "DK", "PL", "GB"],
                onlyCountries: [],
                ignoredCountries: [],
                autocomplete: "off",
                name: "telephone",
                maxLen: 25,
                wrapperClasses: "",
                inputClasses: "",
                dropdownOptions: {
                    disabledDialCode: false
                }
            },
            emailError: false,
            emailErrorMsg: [],
            phoneError: false,
            phoneErrorMsg: []
        }
    },
    watch: {
        emailInput (val) {
            this.emailInput = val.replace(/[\s/]/g, '');
        },
        errorResponse: {
            handler(errors: CreateTenantErrorResponse | null){
                if(errors){
                    if(Object.hasOwn(errors, CreateTenantErrorTypes.email)){
                        this.emailError = true
                        this.emailErrorMsg = errors[CreateTenantErrorTypes.email as keyof typeof errors] ?? []
                    }
                    if(Object.hasOwn(errors, CreateTenantErrorTypes.phoneNumber)){
                        this.phoneError = true
                        this.phoneErrorMsg = errors[CreateTenantErrorTypes.phoneNumber as keyof typeof errors] ?? []
                    }
                }
            }
        }
    },
    computed: {
        isFormValid(): boolean {
            
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const form: any = this?.$refs?.personalInfoForm
            return form.validate() ?? false
        },
        userEmail(): string {
			return this.$vStore.state.oidcStore.user.email
		},
        userPhoneNumber(): string {
			return this.$vStore.state.oidcStore.user.phone_number
		},
        isPersonalInfoStored(): boolean {
            const tenantData: CreateTenantPayload = this.$store.state.context.createTenantFormData
            if(tenantData.phoneNumber && tenantData.email){
                return true
            }
            return false
        },
        getStoredFormData(): CreateTenantPayload {
            return this.$store.state.context.createTenantFormData
        }
    },
    mounted() {
        const storedData: CreateTenantPayload = this.getStoredFormData
        this.emailInput = storedData.email
        this.phoneInput = storedData.phoneNumber
    },
    methods: {
        async updatePersonalInfo(){
            if(this.isFormValid || this.isPrimaryContact){
                const tenantData: CreateTenantPayload = await this.$store.state.context.createTenantFormData
                tenantData.phoneNumber = this.phoneInput.replace(/\s/g, '') ?? ""
                tenantData.email = this.emailInput ?? ""
                await this.$store.dispatch("context/setCreateTenantFormData", tenantData)
            }else if(this.isPersonalInfoStored){
                // if form is not valid, and has info stored, clear data to make form invalid
                const tenantData: CreateTenantPayload = await this.$store.state.context.createTenantFormData
                tenantData.phoneNumber = ""
                tenantData.email = ""
                await this.$store.dispatch("context/setCreateTenantFormData", tenantData)
            }
        },
        async autofillPrimaryContact(){
            console.log("[PrimaryContactInput] - Autofilling...")
            let newEmail = ""
            let newPhoneNumber = ""

            if(this.isPrimaryContact){
                // update the form object
                newEmail = this.userEmail
                newPhoneNumber = this.userPhoneNumber
            }

            // Set input data - this will trigger updatePersonalInfo()
            this.emailInput = newEmail
            this.phoneInput = newPhoneNumber
        }

    }
});
